import React from "react"
import SEO from "@components/seo"
import * as data from "@data/parentDash"
import Layout from "@components/layout"
import CloseAccount from "@components/_molecules/close_account"

const AutoClosurePage = () => {
  const { accountClosure } = data
  return (
    <Layout type="app">
      <SEO title={accountClosure.title} />
      <CloseAccount />
    </Layout>
  )
}

export default AutoClosurePage
