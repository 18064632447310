import React, { Component } from "react"
import "./style.scss"
import axios from "axios"
import * as headers from "@data/headers"
import BackPage from "@components/_molecules/back_parent_dashboard"
import FormButton from "@components/_atoms/button_form_input_submit"
import GeneralSubmitMessage from "@components/_atoms/general_submit_message"
import * as data from "@data/parentDash"
import { format } from "path"
import * as errors from "@services/errors"
import { url } from "inspector"

class CLoseAccount extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange = event => {}

  handleSubmit = event => {}

  componentDidMount = () => {}

  render() {
    const { accountClosure } = data
    return (
      <section className="standard">
        <div className="heading-size">
          <BackPage data={accountClosure.heading} />
        </div>
        <div className="close-account">
          <p className="close-header">Close your Account</p>
          <p>
            Please contact customer support if you want to close your Learn &
            Earn account.
          </p>
          <a href="https://learnandearnsupport.atlassian.net/servicedesk/customer/portal/2/create/22">
            Contact Support
          </a>
        </div>
      </section>
    )
  }
}
export default CLoseAccount
